import { FaqItems } from "./types";

export const faqItems: FaqItems = [
  {
    question: "How much deposit do I need?",
    answer:
      "You don’t need a deposit. That's the beauty of Keyzy! If you have savings you can keep hold of them until you're ready to buy the home. We also convert up to 25% of your monthly payments towards reducing the price you pay for the home later.",
  },
  {
    question: "What is the minimum household income required?",
    answer: "You need to have a minimum household income of £30,000 before tax.",
  },
  {
    question: "What are the basic eligibility criteria?",
    answer:
      'You will need to be over 18 and have the right to live in the UK. We will also do an industry-standard check on you using secure and modern technology including:<ul class="pl-10 list-disc"><li>Verifying your income and outgoings (using Open Banking)</li> <li>Checking for fraud and bankruptcy (using credit bureaus)</li><li>Reference checks with landlords and employers</li></ul>',
  },
  {
    question: "What happens if I want to move somewhere else?",
    answer:
      "At the end of your lease you are free to leave, but we still want you to get any potential increase in the property value. You can find a buyer for the home at any time during your lease, and keep all the upside. You just need to make sure it is sold at a price equal or above the original purchase price including out costs. You also get up to 25% of your rental payments back (minus an early exit fee of 2% for selling the home).",
  },
  {
    question: "How much do I pay Keyzy to buy the home?",
    answer:
      "If you choose to buy, the price you pay will be made up of the original purchase price we paid for the home plus our costs (e.g., stamp duty and legal fees). This price is agreed before your lease starts. Each month, up to 25% of your rent payments go towards reducing the eventual price you pay. In other words, your purchase price will go down over time.",
  },
];
