import Image from "next/image";
import Link from "next/link";

import SubLayout from "@/containers/layout/LandingLayout/SubLayout";
import { FC } from "react";
import routes from "@/constants/routes";

const Hero: FC = () => {
  return (
    <div className="w-full pt-8 text-white pb-52 md:pb-20 xl:pb-8 bg-landing-blue">
      <SubLayout allowOverflow>
        <div className="flex flex-col items-center justify-center pb-12 mx-auto md:flex-row ">
          <div className="w-full xl:ml-16 2xl:ml-8 md:w-2/5 md:pt-0 2xl:pb-12">
            <h1 className="text-5xl font-bold md:text-6xl xl:text-7xl 2xl:text-8xl whitespace-nowrap">
              Move in now,
              <br />
              <span className="text-5xl text-landing-orange md:text-6xl xl:text-7xl 2xl:text-8xl">
                fix your rent,
              </span>
              <br />
              <span className="text-5xl  md:text-6xl xl:text-7xl 2xl:text-8xl">buy later.</span>
            </h1>
            <h2 className="font-serif text-xl xl:text-2xl md:mt-4 xl:mt-7 md:pr-1 md:leading-8 mt-7 max-w-[17.75rem] xl:max-w-[30.375rem]">
              The zero deposit, rent to buy solution creating a better path to home ownership.
            </h2>
            <Link href={routes.application.root}>
              <a className="inline-block px-4 py-3 mx-auto text-base font-bold transition duration-300 ease-in-out transform rounded shadow mt-7 xl:mt-12 bg-landing-orange text-landing-blue hover:scale-105 2xl:h-16 2xl:text-2xl 2xl:pt-4 w-full md:w-auto text-center">
                Sign up now
              </a>
            </Link>
          </div>

          <div className="flex md:justify-end md:w-3/5 md:mt-14 xl:mt-0 mt-11">
            <div className="w-[20rem] md:top-10 xl:top-auto flex-grow h-72 xl:w-[44.375rem] xl:h-[37.5rem] 2xl:h-[37rem] relative md:h-80 md:w-[35.625rem] transform scale-110 md:-left-0 md:scale-100">
              <object
                width="100%"
                height="100%"
                type="image/svg+xml"
                data="/landing/hero/homepage-bg-animated.svg"
              />
            </div>
          </div>
        </div>
      </SubLayout>
    </div>
  );
};

export default Hero;
