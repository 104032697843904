import TestimonialsSVG from "@/public/icons/Testimonials.svg";
import TestimonialsBgSVG from "@/public/icons/testimonials-bg.svg";
import Link from "next/link";
import routes from "@/constants/routes";
import Image from "next/image";
import { useState, useRef } from "react";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";

const Testimonials = (): JSX.Element => {
  const [showSection, setShowSection] = useState(false);
  const { width } = useWindowSize();

  return (
    <section className="flex flex-col md:flex-row w-col mx-4 md:flex-col md:mt-12 xl:mt-16 items-center">
      <div className="max-w-full lg:w-[70rem] md:w-full">
        <div className="flex flex-col md:flex-row justify-between">
          <h3 className="flex md:hidden text-4xl lg:text-5xl font-bold text-landing-blue self-center mb-5 md:mb-3 lg:mb-5">
            Meet our homeowners
          </h3>
          <div className="flex w-auto md:w-1/2">
            <div className="relative flex w-full my-auto mr-auto md:mr-5 md:h-2/4 lg:h-fit">
              <div>
                <div className="absolute right-0 max-w-full bottom-0 z-0">
                  <TestimonialsBgSVG />
                </div>
              </div>
              <div className="z-10 flex flex-col top-0 left-0 items-start w-11/12 pb-20">
                <div className="w-full">
                  <TestimonialsSVG className="w-full" />
                  <span className="text-xs">
                    * Stock images used to protect the privacy of our customers
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full mt-12 flex max-w-[340px] lg:max-w-[486px] w-auto md:w-1/2">
            <h3 className="hidden md:flex text-4xl lg:text-5xl font-bold text-landing-blue mb-5 md:mb-3 lg:mb-5">
              Meet our homeowners
            </h3>
            <div className="flex flex-col md:flex-row lg:flex-col items-center">
              <div className="w-full">
                <h4 className="font-serif text-3xl text-landing-blue mb-2">
                  Steve, 29 & Ashna, 30
                </h4>
                <h4 className="text-xl font-bold mb-4">First time buyers</h4>
                <p className="font-base mb-5">
                  We really liked how simple and effective the whole process was. At first we
                  thought the offer was too good to be true - but the fact that it was so easy to
                  communicate with everyone in the team made it so seamless because we could pick up
                  the phone or email anyone if we had any issues or concerns.
                </p>
                <div className="relative flex mt-10 flex-row text-landing-orange font-bold cursor-pointer font-base mb-4">
                  <Link href={routes.customerReviews}>
                    <a className="flex w-full md:w-auto lg:flex self-start items-center justify-center h-12 px-14 py-3 mt-4 text-base font-bold transition duration-300 ease-in-out transform rounded-md whitespace-nowrap text-white md:mt-0 bg-landing-orange hover:scale-105">
                      Read more
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full min-h-[102px] mt-auto md:mt-10 lg:mt-20 mb-auto md:mb-4">
        <Image
          src={
            (width >= Screen.Tablet_768 && "/landing/testimonials/divider.svg") ||
            "/landing/testimonials/divider-small.svg"
          }
          objectFit="contain"
          layout="fill"
          alt="divider"
        />
      </div>
    </section>
  );
};

export default Testimonials;
