import Card from "@/atoms/Card";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";

import GraphIcon from "@/public/landing/companyFeatures/graph.svg?url";
import SoldIcon from "@/public/landing/companyFeatures/sold.svg?url";
import EasyKeyzy from "@/public/landing/companyFeatures/easy-keyzy.svg?url";
import NoSuprises from "@/public/landing/companyFeatures/no-suprises.svg?url";
import NoDeposit from "@/public/landing/companyFeatures/no-deposit.svg?url";
import HorizontalBar from "@/atoms/HorizontalBar";
import VerticalBar from "@/atoms/VerticalBar";

const CompanyFeatures = (): JSX.Element => {
  return (
    <SubLayout allowOverflow className="bg-white">
      <section className="relative w-full py-16 md:pb-0">
        <div id="why" className="absolute top-0" />
        <h2 className="text-4xl font-bold text-center xl:text-5xl text-landing-blue mb-11 xl:mb-14">
          Why Keyzy
        </h2>
        <div className="flex flex-col md:flex-row md:justify-center md:h-60">
          <Card
            title="Rent that rewards"
            subtitle="Keyzy converts part of your rent into a deposit when you buy your home."
            image={GraphIcon}
          />

          <HorizontalBar className="my-4 md:hidden" />
          <VerticalBar className="hidden md:inline-block md:mx-20 xl:mx-28" />

          <Card
            title="Property Price Lockdown"
            subtitle="Today’s property price is locked in. You keep the gains."
            image={SoldIcon}
          />
          <HorizontalBar className="my-4 md:hidden" />
        </div>

        <HorizontalBar className="hidden w-full md:my-11 xl:my-14 md:inline-block" />

        <div className="block h-64 md:flex md:justify-center md:flex-row mb-11">
          <Card
            title="Easy Keyzy"
            subtitle="Keyzy handles the buying process. No sweat."
            size="small"
            image={EasyKeyzy}
          />

          <HorizontalBar className="inline-block my-4 md:hidden" />
          <VerticalBar className="hidden md:inline-block md:mx-7 xl:mx-20" />

          <Card
            title="No surprises"
            subtitle="No unexpected fees or increases. Monthly payments stay the same."
            size="small"
            image={NoSuprises}
          />
          <HorizontalBar className="inline-block my-4 md:hidden" />
          <VerticalBar className="hidden md:inline-block md:mx-7 xl:mx-20 " />

          <Card
            title="No deposit, no problem"
            subtitle="We know how hard it is to save. Get a home, 0% deposit required. "
            size="small"
            image={NoDeposit}
          />
        </div>
      </section>

      <div className="absolute left-0 right-0 -bottom-24 md:-bottom-32 xl:-bottom-36 overflow-hidden bg-repeat-x h-24 md:h-32 xl:h-36 bg-[url('/landing/companyFeatures/city-outline-mobile.svg')] md:bg-[url('/landing/companyFeatures/city-outline-tablet.svg')] xl:bg-[url('/landing/companyFeatures/city-outline-desktop.svg')]" />
    </SubLayout>
  );
};

export default CompanyFeatures;
