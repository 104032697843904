import "rc-slider/assets/index.css";

import { Props } from "../types";

const CalculatorMobile = ({ keyzyBudget, highStreetBanksBudget }: Props) => {
  return (
    <div className="relative z-50 mt-9 ">
      <div className="flex flex-col items-start">
        <h3 className="text-sm font-bold uppercase">deposit needed</h3>
        <span className="my-3 text-2xl font-bold text-landing-cherry">{keyzyBudget?.deposit}</span>
        <span className="text-base font-normal">{`High street banks: ${highStreetBanksBudget?.deposit}+`}</span>
      </div>

      <div className="w-full h-px mx-auto my-5 bg-landing-gray" />

      <div className="flex flex-col items-start">
        <h3 className="text-sm font-bold uppercase">Estimated budget</h3>
        <span className="my-3 text-2xl font-bold text-landing-cherry">
          {keyzyBudget?.indicativeBudget}
        </span>
        <div className="flex flex-wrap items-center">
          <span className="mr-1 text-base font-normal">High street banks: </span>
          <span className="text-base font-normal">{highStreetBanksBudget?.indicativeBudget}</span>
        </div>
      </div>
    </div>
  );
};

export default CalculatorMobile;
