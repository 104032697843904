import "rc-slider/assets/index.css";
import Image from "next/image";

import { Props } from "../types";

const CalculatorDesktop = ({ keyzyBudget, highStreetBanksBudget }: Props) => {
  return (
    <div className="relative z-40 w-full">
      <div className="w-full mt-6">
        <table className="w-full text-right table-fixed">
          <thead>
            <tr className="text-sm uppercase border-b bold border-landing-blue border-opacity-10">
              <th className="border-r border-landing-blue border-opacity-10 w-44"></th>
              <th className="w-32 p-3 border-r border-landing-blue border-opacity-10">
                Deposit needed
              </th>
              <th>Estimated budget</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-2xl border-b text-landing-cherry text-bold border-landing-blue border-opacity-10">
              <th className="text-center border-r border-landing-blue border-opacity-10">
                <Image
                  src="/shared/keyzy-horizontal-white.svg"
                  alt="Keyzy"
                  width="112"
                  height="28"
                />
              </th>
              <th className="px-5 border-r border-landing-blue border-opacity-10">
                {keyzyBudget?.deposit}
              </th>
              <th className="py-6">{keyzyBudget?.indicativeBudget}</th>
            </tr>

            <tr className="text-xl font-normal text-landing-blue">
              <th className="text-lg font-normal text-center border-r border-landing-blue border-opacity-10">
                High street banks
              </th>
              <th className="px-5 font-normal border-r border-landing-blue border-opacity-10">
                {`${highStreetBanksBudget?.deposit}+`}
              </th>
              <th className="py-6 font-normal">{highStreetBanksBudget?.indicativeBudget}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalculatorDesktop;
