import Head from "next/head";
import Image from "next/image";
import { ReactElement } from "react";

import ApplyNow from "@/components/landing/ApplyNow";
import BuyingBudget from "@/components/landing/BuingBudget";
import CompanyFeatures from "@/components/landing/CompanyFeatures";
import ContactBanner from "@/components/landing/ContactBanner";
import Faq from "@/components/landing/Faq";
import Hero from "@/components/landing/Hero";
import HowItWorksCarousel from "@/components/landing/HowItWorksCarousel";
import { MainLayout } from "@/containers/layout/LandingLayout";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import Testimonials from "@/components/landing/Testimonials";
import LatestArticles from "@/components/blog/latestArticles/LatestArticles";
import getConfig from "next/config";

export default function Home() {
  const { width } = useWindowSize();
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      <Head>
        <title>Keyzy Homes: Rent to buy your future home. No deposit required</title>
        <link rel="canonical" href="https://www.keyzy.com" />
        <meta
          name="description"
          content="Keyzy is a zero deposit, rent to buy solution helping young professionals, key workers and other first-time buyers to become future homeowners."
        />
        <meta
          name="twitter:card"
          content="Keyzy is a zero deposit, rent to buy solution helping young professionals, key workers and other first-time buyers to become future homeowners."
          key="twcard"
        />
        <meta name="twitter:creator" content="keyzyhomes" key="twhandle" />
        <meta property="og:url" content="/" key="ogurl" />
        <meta
          property="og:image"
          content="https://www.keyzy.com/landing/keyzy-meta-image.png"
          key="ogimage"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="Keyzy" key="ogsitename" />
        <meta
          property="og:title"
          content="Keyzy Homes: Rent to buy your future home. No deposit required."
          key="ogtitle"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta
          property="og:description"
          content="Keyzy is a zero deposit, rent to buy solution helping young professionals, key workers and other first-time buyers to become future homeowners."
          key="ogdesc"
        />
      </Head>

      <Hero />
      <BuyingBudget />

      <div className="w-full h-px mx-auto md:hidden bg-grey-200" />

      <CompanyFeatures />
      <ContactBanner />
      <HowItWorksCarousel />

      <div className="relative w-auto h-8 mx-5 my-6 md:flex xl:hidden md:mx-6 lg:mx-11 xl:px-20">
        <Image
          src={`/landing/${width < Screen.Tablet_768 ? "mobile-doodle.svg" : "tablet-doodle.svg"}`}
          alt="doodle"
          layout="fill"
          objectFit="contain"
        />
      </div>
      <Testimonials />
      <LatestArticles
        selectedCategory={0 as number}
        excludeCategories={publicRuntimeConfig.NEXT_BLOG_LANDLORD_CATEGORY_ID}
      />
      <Faq />
      <ApplyNow />
    </>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>;
};
