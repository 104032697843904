import BudgetCalculator from "@/components/landing/BudgetCalculator";
import SubLayout from "@/containers/layout/LandingLayout/SubLayout";
import { Buttons, Description } from "./components";

const BuyingBudget = () => (
  <div className="md:bg-landing-ivory pb-12 relative h-[55rem] md:h-[40.625rem] xl:h-[34.5rem] text-landing-blue ">
    <div id="calculator" className="absolute top-16 xl:-top-48" />
    <SubLayout allowOverflow>
      <div className="relative flex flex-col justify-between w-full mx-auto max-w-7xl md:flex-row ">
        <div className="relative w-full -top-40 md:-top-24 xl:-top-44 xl:min-w-fit md:w-3/5 lg:1/2 xl:w-3/5">
          <div className="mx-auto xl:w-full">
            <BudgetCalculator />
          </div>
        </div>
        <div className="md:ml-7 xl:ml-20 md:max-w-[24.1rem] xl:max-w-[26rem] md:w-2/5 lg:1/2 xl:w-2/5">
          <div className="hidden xl:w-96 md:inline-block">
            <Description />
            <Buttons className="-top-40 md:top-0" />
          </div>
        </div>
      </div>
    </SubLayout>
  </div>
);

export default BuyingBudget;
