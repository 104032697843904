import routes from "@/constants/routes";
import { SubLayout } from "@/containers/layout/LandingLayout";
import getConfig from "next/config";
import Link from "next/link";

const { publicRuntimeConfig } = getConfig();

const ApplyNow = (): JSX.Element => {
  return (
    <SubLayout allowOverflow>
      <section className="relative z-30 flex flex-col items-center justify-center w-full mx-auto text-white mt-9 md:mt-0 md:-top-5 xl:top-0 py-9 px-7 md:h-40 xl:h-60 rounded-2xl p-14 bg-landing-denim">
        <h2 className="text-3xl font-bold text-center xl:text-5xl max-w-[360px] md:max-w-fit">
          Apply to rent to buy your future home
        </h2>

        <div className="flex flex-col items-center justify-center text-center md:mt-5 md:flex-row">
          <p className="my-6 font-serif text-xl font-normal md:my-auto xl:text-2xl whitespace-nowrap">
            Get in touch:{" "}
            <span className="underline">
              {" "}
              <a href="mailto:hello@keyzy.com" target="_blank" rel="noreferrer">
                hello@keyzy.com
              </a>
            </span>
          </p>

          <div className="hidden w-px h-12 bg-white md:mx-4 xl:mx-12 md:inline-block" />

          <p className="font-serif text-xl font-normal xl:text-2xl">Or apply for your budget</p>

          <Link href={routes.application.root}>
            <a className="flex min-w-[9rem] items-center justify-center w-full h-12 px-4 py-3 mt-4 text-base font-bold transition duration-300 ease-in-out transform bg-white rounded-md whitespace-nowrap text-landing-denim md:text-white md:mt-0 md:w-auto md:bg-landing-blue xl:bg-landing-orange md:ml-4 xl:ml-9 hover:scale-105">
              Sign up now
            </a>
          </Link>
        </div>
      </section>
    </SubLayout>
  );
};

export default ApplyNow;
