import { Props } from "./types";

const Description = ({ className }: Props) => {
  return (
    <div className={`${className} xl:w-96`}>
      <h2 className="text-4xl font-bold md:mt-36 xl:mt-24 xl:text-5xl ">
        Keyzy gives you superior buying power
      </h2>
      <div className="text-base mt-7">
        We offer a higher budget than traditional mortgage lenders. And you don&apos;t require a
        deposit.
      </div>
    </div>
  );
};

export default Description;
