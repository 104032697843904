import { useEffect, useState } from "react";
import { BudgetModel } from "@/components/landing/BudgetCalculator/types";
import { formatPrice } from "@/utils/formatPrice";

export function useCalculatorBudget(annualIncome: number) {
  const [keyzyBudget, setKeyzyBudget] = useState<BudgetModel>();
  const [highStreetBanksBudget, setHighStreetBanksBudget] = useState<BudgetModel>();

  useEffect(() => {
    setKeyzyBudget({
      deposit: formatPrice(0),
      indicativeBudget: `${formatPrice(
        (Math.round(annualIncome * 4) / 10000) * 10000,
      )}-${formatPrice(Math.round((annualIncome * 4.6) / 10000) * 10000)}`,
    });

    setHighStreetBanksBudget({
      deposit: formatPrice((Math.round(annualIncome * 3.5) / 10000) * 1000),
      indicativeBudget: `${formatPrice(
        (Math.round(annualIncome * 3.5) / 10000) * 10000,
      )}-${formatPrice(Math.round((annualIncome * 4.5) / 10000) * 10000)}`,
    });
  }, [annualIncome]);

  return [keyzyBudget, highStreetBanksBudget] as const;
}
